import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: sessionStorage.getItem("loggedIn") === "true",
  email: sessionStorage.getItem("email") || "",
  firstName: sessionStorage.getItem("firstName") || "",
  lastName: sessionStorage.getItem("lastName") || "",
  userId: sessionStorage.getItem("userId") || "",
  idToken: sessionStorage.getItem("idToken") || "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
    setUserDetails(state, action) {
      const { email, firstName, lastName, userId, idToken } = action.payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.userId = userId;
      state.idToken = idToken;
    },
    resetUser(state) {
      state.loggedIn = false;
      state.email = "";
      state.firstName = "";
      state.lastName = "";

      sessionStorage.removeItem("loggedIn");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("firstName");
      sessionStorage.removeItem("lastName");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("idToken");
    },
  },
});

export const { setLoggedIn, setUserDetails, resetUser } = userSlice.actions;
export default userSlice.reducer;
